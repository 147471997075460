<template>
  <selected-filters
    @resetData="resetData($event)"
    :selectedList="selectedList"
  />
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";
import allCountry from "@/constants/all-countries";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  components: { SelectedFilters },
  mixins: [ DateFormatMixin ],
  computed: {
    ...mapState({
      filterModel: state => state.addProviderModal.filterModel,
      filter: state => state.addProviderModal.filter,
      providerPriceGroups: state => state.priceGroupAdmin.priceGroupAllList
    }),

    getCountryName() {
      let name = ''
      if (this.filterModel.country || this.filterModel.country === 0) {
        name = allCountry.filter(
        item => item.iso2 === this.filterModel.country
      )[0].name;
      }
      return name
    },

    getPriceGroupName() {
      let priceGroupName = ''
      if (this.filterModel.priceGroup !== 'default' && this.filterModel.priceGroup) {
        this.providerPriceGroups.map(item => {
          if (item.id === this.filterModel.priceGroup) {
            priceGroupName = item.name[0]?.name
          }
        })
      }

      return priceGroupName
    },

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: `<b>Search</b>: ${this.filterModel.search}`,
          resetArgument: 'search'
        },
        {
          ifCondition: this.filterModel.country || this.filterModel.country === 0,
          boxText: `<b>Country</b>: ${ this.getCountryName }`,
          resetArgument: 'country'
        },
        {
          ifCondition: this.filterModel.clientsLimit[0] || this.filterModel.clientsLimit[1],
          boxText: `<b>Clients</b>: ${ this.filterModel.clientsLimit[0] } - ${ this.filterModel.clientsLimit[1] }`,
          resetArgument: 'clientsLimit'
        },
        {
          ifCondition: this.filterModel.resellersLimit[0] || this.filterModel.resellersLimit[1],
          boxText: `<b>Resellers</b>: ${ this.filterModel.resellersLimit[0] } - ${ this.filterModel.resellersLimit[1] }`,
          resetArgument: 'resellersLimit'
        },
        {
          ifCondition: this.filterModel.totalLoginsLimit[0] || this.filterModel.totalLoginsLimit[1],
          boxText: `<b>Total Logins</b>: ${ this.filterModel.totalLoginsLimit[0] } - ${ this.filterModel.totalLoginsLimit[1] }`,
          resetArgument: 'totalLoginsLimit'
        },
        {
          ifCondition: this.filterModel.activeLogins[0] || this.filterModel.activeLogins[1],
          boxText: `<b>Active Logins</b>: ${ this.filterModel.activeLogins[0] } - ${ this.filterModel.activeLogins[1] }`,
          resetArgument: 'activeLogins'
        },
        {
          ifCondition: this.filterModel.priceGroup,
          boxText: `<b>Price Group</b>: ${this.filterModel.priceGroup === 'default' ? 'Default' : this.getPriceGroupName}`,
          resetArgument: 'priceGroup'
        },
        {
          ifCondition: this.filterModel.balance[0] || this.filterModel.balance[1],
          boxText: `<b>Balance</b>: ${ this.filterModel.balance[0] } - ${ this.filterModel.balance[1] }`,
          resetArgument: 'balance'
        },
        {
          ifCondition: this.filterModel.debt[0] || this.filterModel.debt[1],
          boxText: `<b>Debt</b>: ${ this.filterModel.debt[0] } - ${ this.filterModel.debt[1] }`,
          resetArgument: 'debt'
        },
        {
          ifCondition: this.filterModel.creditAmount[0] || this.filterModel.creditAmount[1],
          boxText: `<b>Credit Amount</b>: ${ this.filterModel.creditAmount[0] } - ${ this.filterModel.creditAmount[1] }`,
          resetArgument: 'creditAmount'
        },
        {
          ifCondition: this.filterModel.creditAutoextend,
          boxText: `<b>Credit Autoextend</b>: Yes`,
          resetArgument: 'creditAutoextend'
        },
        {
          ifCondition: this.filterModel.daysRemainCredit[0] || this.filterModel.daysRemainCredit[1],
          boxText: `<b>Days Remain To Credit End</b>: ${ this.filterModel.daysRemainCredit[0] } - ${ this.filterModel.daysRemainCredit[1] }`,
          resetArgument: 'daysRemainCredit'
        },
        {
          ifCondition: this.filterModel.currentMonthPayments[0] || this.filterModel.currentMonthPayments[1],
          boxText: `<b>Current Month Payments</b>: ${ this.filterModel.currentMonthPayments[0] } - ${ this.filterModel.currentMonthPayments[1] }`,
          resetArgument: 'currentMonthPayments'
        },
        {
          ifCondition: this.filterModel.currentMonthIncomes[0] || this.filterModel.currentMonthIncomes[1],
          boxText: `<b>Current Month Incomes</b>: ${ this.filterModel.currentMonthIncomes[0] } - ${ this.filterModel.currentMonthIncomes[1] }`,
          resetArgument: 'currentMonthIncomes'
        },
        {
          ifCondition: this.filterModel.creditDate?.start && this.filterModel.creditDate?.end,
          boxText: `<b>Credit Grant Date</b>: ${ this.getDate(this.filterModel.creditDate?.start) } - ${ this.getDate(this.filterModel.creditDate?.end) }`,
          resetArgument: 'creditDate'
        },
        {
          ifCondition: this.filterModel.registerDate?.start && this.filterModel.registerDate?.end,
          boxText: `<b>Date Registered</b>: ${ this.getDate(this.filterModel.registerDate?.start) } - ${ this.getDate(this.filterModel.registerDate?.end) }`,
          resetArgument: 'registerDate'
        },
      ]
    }
  },
  methods: {
    ...mapActions({
      getProvidersList: 'addProviderModal/getProvidersList'
    }),
    ...mapMutations({
      filterProviders: 'addProviderModal/filterProviders'
    }),
    async resetData(type) {
      if (type === 'creditDate') {
        this.filterModel.resetCurrentData('creditDate')
      }

      if (type === 'registerDate') {
        this.filterModel.resetCurrentData('registerDate')
      }

      if (type !== 'creditDate' && type !== 'registerDate') {
        this.filterModel.resetCurrentData(type)
      }

      this.filterProviders()
      await this.getProvidersList(this.filter)
    },
    getDate(date) {
      return this.$moment(date)
        .format(this.ottDateFormat)
        .valueOf();
    }
  },
};
</script>

<template>
    <ott-dialog
        :is-open="providers.isOpenRefreshModal"
        :width="700"
        @outside="setData({ isOpenRefreshModal: false })"
    >

<!--      <tree-item-->
<!--          class="item"-->
<!--          :item="treeData"-->
<!--          @make-folder="makeFolder"-->
<!--          @add-item="addItem"-->
<!--      ></tree-item>-->
    </ott-dialog>
</template>

<script>
    import OttDialog from "../../components/vuetifyComponents/OttDialog";
    import TreeItem from "./TreeItem";
    import {mapMutations, mapState} from "vuex";
    export default {
      props:{
        data: Object
      },
        name: "RefreshDataModal",
        components: {OttDialog, TreeItem},
        data() {
          return {
            treeData: {
              name: "My Tree",
              children: [
                { name: "hello" },
                { name: "wat" },
                {
                  name: "child folder",
                  children: [
                    {
                      name: "child folder",
                      children: [{ name: "hello" }, { name: "wat" }]
                    },
                    { name: "hello" },
                    { name: "wat" },
                    {
                      name: "child folder",
                      children: [{ name: "hello" }, { name: "wat" }]
                    }
                  ]
                }
              ]
            }
          }
        },
        computed: {
            ...mapState({
                providers: state => state.providers,
            })
        },
        methods: {
            ...mapMutations({
                setData: 'providers/setData'
            }),
          makeFolder: function(item) {
            Vue.set(item, "children", []);
            this.addItem(item);
          },
          addItem: function(item) {
            item.children.push({
              name: "new stuff"
            });
          }
        }
    }
</script>

<style scoped>

</style>
<template>
  <div class="P-filter-provider">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-input>
    </div>
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.country"
        @emitFunc="filterData('country')"
        :items="countryList"
        :clear="true"
        label="Country"
        selected-text="name"
      >
      </ott-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.clientsLimit"
        :min="filterRangeData.clients[0]"
        :max="filterRangeData.clients[1]"
        @updateRange="filterData"
        @clearData="resetData('clientsLimit')"
        label="Clients"

      ></ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.resellersLimit"
        :min="filterRangeData.resellers[0]"
        :max="filterRangeData.resellers[1]"
        @updateRange="filterData"
        @clearData="resetData('resellersLimit')"
        label="Resellers"

      ></ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.totalLoginsLimit"
        :min="filterRangeData.totalLogins[0]"
        :max="filterRangeData.totalLogins[1]"
        @updateRange="filterData"
        @clearData="resetData('totalLoginsLimit')"
        label="Total logins"
      ></ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.activeLogins"
        :min="filterRangeData.activeLogins[0]"
        :max="filterRangeData.activeLogins[1]"
        @updateRange="filterData"
        @clearData="resetData('activeLogins')"
        label="Active logins"

      ></ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.priceGroup"
        :items="priceGroupAdmin.priceGroupAllList"
        :clear="true"
        :isTranslate="true"
        @emitFunc="filterData('priceGroup')"
        @emmitClearData="filterModel.resetCurrentData('priceGroup')"
        selected-text="name"
        label="Price Group"
      >
      </ott-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.balance"
        :min="filterRangeData.balance[0]"
        :max="filterRangeData.balance[1]"
        @updateRange="filterData"
        @clearData="resetData('balance')"
        prefix="$"
        label="Balance"

      ></ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.debt"
        :min="filterRangeData.debt[0]"
        :max="filterRangeData.debt[1]"
        @updateRange="filterData"
        @clearData="resetData('debt')"
        label="Debt"
        prefix="$"
      ></ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.creditAmount"
        :min="filterRangeData.creditAmount[0]"
        :max="filterRangeData.creditAmount[1]"
        @updateRange="filterData"
        @clearData="resetData('creditAmount')"
        label="Credit amount"
        prefix="$"
      ></ott-range-select>
    </div>
    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.creditDate"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        @input="filterData"
        is-range
        locale="en"
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
            <div class="ott-date-picker-cont Ott-input-class:">
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.start"
                        v-on="inputEvents.start"
                        outlined
                        dense
                        height="38"
                        label="Credit Start"
                    />
                    <span
                        class="P-date-icon neutral--text"
                        @click="resetData('creditDate')"
                        :class="
                          !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                    ></span>
                </div>
                <span class="pick-to neutral--text">To</span>
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.end"
                        v-on="inputEvents.end"
                        outlined
                        dense
                        height="38px"
                        label="Credit End"
                    />
                    <span
                        @click="resetData('creditDate')"
                        :class="
                            !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                        class="P-date-icon neutral--text"
                    ></span>
                </div>
            </div>
        </template>
      </DatePicker>
    </div>

    <div class="P-input-block">
      <OttCheckbox
        v-model="filterModel.creditAutoextend"
        @changeCheckbox="filterData('creditAutoextend')"
        label="Credit autoextend"
      />
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.daysRemainCredit"
        :min="filterRangeData.creditEndRemainDay[0]"
        :max="filterRangeData.creditEndRemainDay[1]"
        @updateRange="filterData"
        @clearData="resetData('daysRemainCredit')"
        label="Days remain to credit end"
      ></ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.currentMonthPayments"
        :min="filterRangeData.currentMonthPayments[0]"
        :max="filterRangeData.currentMonthPayments[1]"
        @updateRange="filterData"
        @clearData="resetData('currentMonthPayments')"
        label="Current month payments"
        prefix="$"
      ></ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.currentMonthIncomes"
        :min="filterRangeData.currentMonthIncome[0]"
        :max="filterRangeData.currentMonthIncome[1]"
        @updateRange="filterData"
        @clearData="resetData('currentMonthIncomes')"
        label="Current month incomes"
        prefix="$"
      ></ott-range-select>
    </div>

    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.registerDate"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        @input="filterData"
        locale="en"
        is-range
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
            <div class="ott-date-picker-cont Ott-input-class:">
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.start"
                        v-on="inputEvents.start"
                        outlined
                        dense
                        height="38"
                        label="Register Start"
                    />
                    <span
                        class="P-date-icon neutral--text"
                        @click="resetData('registerDate')"
                        :class="
                          !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                    ></span>
                </div>
                <span class="pick-to neutral--text">To</span>
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.end"
                        v-on="inputEvents.end"
                        outlined
                        dense
                        height="38px"
                        label="Register End"
                    />
                    <span
                        @click="resetData('registerDate')"
                        :class="
                            !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                        class="P-date-icon neutral--text"
                    ></span>
                </div>
            </div>
        </template>
      </DatePicker>        
    </div>
    <SelectedFiltersList v-if="filterModel.filterCount.length"/>
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions } from "vuex";
  import DateFormatMixin from "@/mixins/DateFormatMixin";
  import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin"; 
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import OttSelect from "@/components/vuetifyComponents/OttSelect";
  import allCountry from "@/constants/all-countries";
  import OttRangeSelect from "@/components/customComponents/OttRangeSelect";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import SelectedFiltersList from "./SelectedFiltersList";
  import DatePicker from "v-calendar/src/components/DatePicker";

  export default {
    components: {
      SelectedFiltersList,
      OttCheckbox,
      OttRangeSelect,
      OttSelect,
      OttInput,
      DatePicker
    },
    mixins: [DateFormatMixin, ConvertTimeZoneMixin],
    data() {
      return {
        countryList: allCountry,
        rangeData: [10, 30],
      };
    },

    watch: {
      dataMain: function (data) {
        this.setFiltersDataRange(data)
      }
    },
    
    computed: {
      ...mapState({
        filterModel: state => state.addProviderModal.filterModel,
        addProviderModal: state => state.addProviderModal,
        dataMain: state => state.addProviderModal.dataMain,
        filterRangeData: state => state.addProviderModal.filterRangeData,
        filter: state => state.addProviderModal.filter,
        priceGroupAdmin: state => state.priceGroupAdmin,
      })
    },

    created() {
      Promise.all([this.getPriceGroupByTypeList({ type: 1 }), this.getPriceGroupByTypeList({ type: 2 })]).then(data => {
        this.generateAllPriceGroups(data)
      })
    },

    methods: {
      ...mapActions({
        getProvidersList: 'addProviderModal/getProvidersList',
        getPriceGroupByTypeList: 'priceGroupAdmin/getPriceGroupByTypeList',
      }),

      ...mapMutations({
        filterProviders: 'addProviderModal/filterProviders',
        setFiltersDataRange: 'addProviderModal/setFiltersDataRange',
        setFilterPage: 'addProviderModal/setFilterPage',
        sortColumn: 'addProviderModal/sortColumn',
        generateAllPriceGroups: 'priceGroupAdmin/generateAllPriceGroups',
      }),

      async filterData(type) {
        this.setFilterPage(1)
        this.filterProviders()
        // switch (type) {
        //   case "search": {
        //     if (!this.filterModel.search) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }

        //   case "country": {
        //     if (!this.filterModel.country) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "clientsLimit": {
        //     if (this.filterModel.clientsLimit[0] && this.filterModel.clientsLimit[1]) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "resellersLimit": {
        //     if (this.filterModel.resellersLimit[0] && this.filterModel.resellersLimit[1]) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "totalLoginsLimit": {
        //     if (this.filterModel.totalLoginsLimit[0] && this.filterModel.totalLoginsLimit[1]) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "activeLogins": {
        //     if (this.filterModel.activeLogins[0] && this.filterModel.activeLogins[1]) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "priceGroup": {
        //     if (this.filterModel.priceGroup !== null && this.filterModel.priceGroup !== '') {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "balance": {
        //     this.balance = [0, 0];
        //     break;
        //   }
        //   case "debt": {
        //     if (this.filterModel.debt[0] && this.filterModel.debt[1]) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "creditAmount": {
        //     if (this.filterModel.creditAmount[0] && this.filterModel.creditAmount[1]) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "daysRemainCredit": {
        //     if (this.filterModel.daysRemainCredit[0] && this.filterModel.daysRemainCredit[1]) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "currentMonthPayments": {
        //     if (this.filterModel.currentMonthPayments[0] && this.filterModel.currentMonthPayments[1]) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "currentMonthIncomes": {
        //     if (this.filterModel.currentMonthIncomes[0] && this.filterModel.currentMonthIncomes[1]) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "creditAutoextend": {
        //     if (!this.filterModel.creditAutoextend) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "creditDate": {
        //     if (!this.filterModel.creditDate?.start && !this.filterModel.creditDate?.end) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        //   case "registerDate": {
        //     if (!this.filterModel.registerDate?.start && !this.filterModel.registerDate?.end) {
        //       this.filterModel.resetCurrentData(type)
        //     }
        //     break;
        //   }
        // }

        this.filterModel.getFilterCount()
        await this.getProvidersList(this.filter)
        this.sortColumn();
        this.$emit('resetSelectedList')

      },
      async resetData(type) {
        this.filterModel.resetCurrentData(type)
        this.filterProviders()
        this.filterModel.getFilterCount()
        await this.getProvidersList(this.filter)
        this.$emit('resetSelectedList')
      }
    }, 
  };
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/datePicker";

  .P-input-block {
    margin-bottom: 20px;
  }

  .P-provider-date {
    .P-date-icon {
      top : 20px;
    }
  }

  ::v-deep {
    .v-text-field__details,
    .v-messages.theme--light{
      display: none;
    }
    .v-input__slot,
    .v-input--dense>.v-input__control>.v-input__slot,
    .range-select {
      margin-bottom: 0;
    }
  }
</style>

<template>
  <div class="P-providers-list ">
    <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }">
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title">
          <h3 class="secondary--text">Providers</h3>
        </div>

        <div class="G-flex">
          <ott-button
            v-if="permissionIs('createOttProvider')"
            :click="openModal"
            class="primary-sm-btn"
            custom-class="approve"
            icon-before
            text="Add Provider"
            icon-before-class="mdi mdi-plus d-inline"
          />
          <ott-button
            v-if="permissionIs('ottSync')"
            :click="refreshPage"
            class="primary-sm-btn"
            custom-class="P-refresh-btn"
            icon-before
            text="Migrate Providers"
            icon-before-class="mdi mdi-refresh"
          />
        </div>
      </div>

      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <OttCheckbox
              :indeterminate="selectNotAll"
              v-model="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
            <div
              v-if="(selectedArrowList.length || selectedArrowListSub.length) && (permissionIs('updateOttProvider') || permissionIs('updateClient') || permissionIs('deleteOttProvider'))"
              class="approve-rej-icon-cont G-flex G-align-center"
            >
              <v-tooltip
                v-if="permissionIs('deleteOttProvider')"
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on" @click="selectMultipleAction('delete')">
                    <span class="mdi mdi-delete-outline neutral--text P-icon"/>
                  </div>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <v-tooltip
                v-if="permissionIs('updateClient')"
                top
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class=" P-settings" @click="selectMultipleAction('pauseClient')">
                    <span class="mdi mdi-pause-circle-outline neutral--text P-icon"
                    ></span>
                  </div>
                </template>
                <span>Pause client</span>
              </v-tooltip>
              <v-tooltip
                v-if="permissionIs('updateClient')"
                top
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on" @click="selectMultipleAction('blockClient')">
                    <span class="mdi mdi-cancel neutral--text P-icon"/>
                  </div>
                </template>
                <span>Block client</span>
              </v-tooltip>
              <v-tooltip
                v-if="permissionIs('updateClient')"
                top
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on">
                    <span class="mdi mdi-swap-vertical neutral--text P-icon"/>
                  </div>
                </template>
                <span>Move to</span>
              </v-tooltip>
            </div>
            <div
              v-if="(selectedArrowList.length || selectedArrowListSub.length) && (permissionIs('updateOttProvider') || permissionIs('updateClient'))"
              class=" ott-menu select-all-menu-cont "
            >
              <v-menu offset-y attach=".select-all-menu-cont">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :ripple="false"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="P-settings"
                  >
                    <span
                      class="P-icon mdi mdi-dots-vertical G-flex-center"
                      :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}"
                    ></span>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, i) in multipleActions"
                    @click="selectMultipleAction(item.value)"
                    :key="i"
                  >
                    <div>
                      <v-list-item-title>
                        <p>
                          {{ item.title }}
                        </p>
                      </v-list-item-title>
                    </div>
                  </v-list-item>
                </v-list>               
              </v-menu>
            </div>
          </div>
          <div class="G-excel-btn">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on" @click="exportData">
                    <span class="mdi mdi-file-excel neutral--text P-icon" />
                  </div>
                </template>
                <span>Download Excel</span>
              </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            :column-config="columnConfigData"
            :is-default="isDefault"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            @resetDataSettingsTable="resetDataTableSettings"
            class="P-providers-columns"
          />
        </div>

        <div>
          <div
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
            class="G-filter-btn"
          >
            <span class="mdi mdi-filter-variant "></span>
            <p>Filter</p>
            <div
              class="active-filter-count"
              v-if="filterModel.filterCount.length > 0"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right "></span>
          </div>
        </div>
      </div>
      <div class="G-component-body page-background">
        <div class="G-sub-block-title">
          <h3 class="secondary--text">Providers List</h3>
        </div>
        <OttUniversalTable
          v-if="!loading"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="dataTable"
          :is-sub-table="true"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :getSubList="getSubList"
          :main-data="dataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="true"
          ref="table-data"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortColumnData"
          @updateColumnWith="updateColumn"
        >
          <template 
            v-if="permissionIs('updateOttProvider') || permissionIs('deleteOttProvider') || permissionIs('getClientList') || permissionIs('getUsers') || permissionIs('getOttProviderBalanceCredit')" 
            slot-scope="props" slot="action"
          >
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="selectAction($event, props, item)"
                  v-for="(item, i) in menuItem"
                  :key="i"
                >
                  <div>
                    <v-list-item-title>
                      <p>{{ item.title }}</p>
                    </v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </OttUniversalTable>
        <OttPaginationForTable
          v-if="providersResult && !loading"
          v-model="rowsPerPage"
          :data-count="addProviderModal.dataCount"
          :available-data-count="dataTable.length"
          :current-page="addProviderModal.currentPage"
          :page-limit="addProviderModal.totalPages"
          @update="updateLimit"
          @updatePage="getProviders"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      class="G-filter-component page-background"
      :class="{ 'P-filter-open': isOpenFilter }"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button class="primary-lg-btn " text="Reset" :click="resetData">
        </ott-button>
      </div>
    </div>
    <AddProviderModal v-if="addProviderModal.modal.isOpen" @resetSelectedList="resetSelectedList" />
    <RejectModalContent v-if="addProviderModal.modal.rejectModal" @rejectProvider="rejectProviders"/>
    <PageLoader v-if="isTableLoading || loading"/>
    <WarningModal 
      v-if="providers.showWarningModal"
      :isModalOpen="providers.showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="setData({ showWarningModal : false })" 
      @btnFunction="warningModalBtnFunc"
    />
    <!--   TODO -->
    <RefreshDataModal :data="liveResponse"/>
  </div>
</template>
<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import OttUniversalTableForComponentsMixin
    from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
  import FilterMixin from "@/mixins/FilterMixin";
  import allCountry from "@/constants/all-countries";
  import DateFormatMixin from "@/mixins/DateFormatMixin";
  import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";

  // components
  import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
  import OttMenu from "@/components/vuetifyComponents/OttMenu";
  import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
  import OttButton from "@/components/vuetifyComponents/OttButton";
  import FilterData from "./FilterData";
  import AddProviderModal from "@/components/addProviderModal/AddProviderModal";
  import CreateProviderModal from "./createProviderModal";
  import PageLoader from "@/components/pageLoader/PageLoader";
  import RejectModalContent from "./RejectModalContent";
  import WarningModal from "@/components/WarningModal";
  import NoDataInTable from "@/components/NoDataInTable";
  import RefreshDataModal from "./RefreshDataModal";

  export default {
    mixins: [OttUniversalTableForComponentsMixin, FilterMixin, DateFormatMixin, ColumnConfigMixin],
    components: {
      RefreshDataModal,
      NoDataInTable,
      RejectModalContent,
      PageLoader,
      CreateProviderModal,
      AddProviderModal,
      FilterData,
      OttButton,
      OttPaginationForTable,
      OttMenu,
      OttHideShowColumnsOption,
      OttCheckbox,
      OttUniversalTable,
      WarningModal
    },

    data() {
      return {
        liveResponse: {},
        defaultColumnConfigData: [],
        columnConfigData: [],
        isShowButton: false,
        menuItem: [
          { title: "Edit", value: 'edit' },
          { title: "Delete", value: 'delete' },
          { title: "Go To Clients Table", value: 'goToClientsTable' },
          { title: "Balance History", value: 'balanceHistory' },
          { title: "Add Reseller", value: 'addReseller' }
        ],
        multipleActionsData: {},
        deletedProviderId: null,
        warningModalType: "",
        warningModalBtnText: "",
        warningModalIsDangerButton: true,
        warningModalIsLoading: false,
        loading: false
      };
    },
    watch: {
      async dataTable() {
        await this.getFiltersData()
      }
    },
    computed: {
      ...mapState({
        providerSyncState: state => state.appModule.providerSyncState,
        providers: state => state.providers,
        filterModel: state => state.addProviderModal.filterModel,
        addProviderModal: state => state.addProviderModal,
        auth: state => state.auth.user,
        // important variables  to get data  and change  configs
        filter: state => state.addProviderModal.filter,
        dataTable: state => state.addProviderModal.dataTable,
        isDefault: state => state.addProviderModal.isDefault,
        defaultSettings: state => state.addProviderModal.defaultSettings,
        getSubList: state => state.addProviderModal.getSubList,
        updateColumnSettingsCommit: state => state.addProviderModal.updateColumnSettingsCommit,
        updateColumnsFromBack: state => state.addProviderModal.updateColumnsFromBack,
        totalResellers: state => state.addProviderModal.totalResellers,
        totalClients: state => state.addProviderModal.totalClients,
        totalActiveLogins: state => state.addProviderModal.totalActiveLogins,
        totalLogins: state => state.addProviderModal.totalLogins,
        totalInactiveLogins: state => state.addProviderModal.totalInactiveLogins,
        totalCurrentMonthPayments: state => state.addProviderModal.totalCurrentMonthPayments,
        totalCurrentMonthIncome: state => state.addProviderModal.totalCurrentMonthIncome,
        totalCreditFromParent: state => state.addProviderModal.totalCreditFromParent,
        totalDebt: state => state.addProviderModal.totalDebt,
        totalOwnChannels: state => state.addProviderModal.totalOwnChannels,
        providersResult: state => state.addProviderModal.providersResult,
        activeTab: state => state.addProviderModal.activeTab,
        isTableLoading: state => state.addProviderModal.isTableLoading
      }),

      defaultColumnConfigs() {
        return [
          {
            title: "Provider ID",
            isTranslate: true,
            style: {minWidth: 140},
            isShow: true,
            isDrag: true,
            key: 'number',
            defaultHide: true,
            defaultDragIndex: 0,
            mainIndex: 0,
            cellView: row => `<p class="G-text-table">${`${row.number} <span class="mdi mdi-check" style="color: ${this.getSyncColor(this.providerSyncState[row.id], row.syncState)}"></span>`}</p>`
          },
          {
            title: "Company Name",
            isTranslate: true,
            style: {minWidth: 150},
            isShow: true,
            isDrag: true,
            key: 'name',
            defaultHide: true,
            defaultDragIndex: 1,
            mainIndex: 1,
            cellView: row => `<p class="G-text-table">${row.name && row.name.length ? row.name[0].name : '-'}</p>`
          },
          {
            title: "Country",
            isTranslate: true,
            style: {minWidth: 150},
            isShow: true,
            isDrag: true,
            key: 'country',
            defaultHide: true,
            defaultDragIndex: 2,
            mainIndex: 2,
            cellView: row => `<p class="G-text-table">${this.getCountryName(row.country)}</p>`
          },
          {
            title: "Company email",
            isTranslate: true,
            style: {minWidth: 240},
            isShow: true,
            isDrag: true,
            key: 'emails',
            defaultHide: true,
            defaultDragIndex: 3,
            mainIndex: 3,
            cellView: row => `<p class="G-text-table">${this.getMainEMail(row.emails)}</p>`
          },
          {
            title: "Company Address",
            isTranslate: true,
            style: {minWidth: 250},
            isShow: true,
            isDrag: true,
            key: 'addresses',
            defaultHide: true,
            defaultDragIndex: 4,
            mainIndex: 4,
            cellView: row => `<p class="G-text-table">${this.getAddress(row.addresses)}</p>`
          },
          {
            title: "Clients",
            isTranslate: true,
            style: {minWidth: 120},
            isShow: true,
            isDrag: true,
            key: 'clients',
            defaultHide: true,
            defaultDragIndex: 5,
            mainIndex: 5,
            cellView: row => `<p class="G-text-table">${row.clients.length ? row.clients[0].clientCount : 0}</p>`,
            totalData: true,
            totalText: "Total Sum",
            className: "P-border-dashed",
            sortData: {
              isSort: true,
              sortKey: "clients",
              sortValue: ""
            },
            cellTotalData: data =>
                    `<p class="G-total-sum">${this.totalClients ? this.totalClients : 0}</p>`
          },
          {
            title: "Resellers",
            isTranslate: true,
            style: {minWidth: 120},
            isShow: true,
            isDrag: true,
            key: 'resellers',
            defaultHide: true,
            defaultDragIndex: 6,
            mainIndex: 6,
            cellView: row => `<p class="G-text-table">${row.resellers.length ? row.resellers[0].resellerCount : 0}</p>`,
            totalData: true,
            totalText: "Total Sum",
            className: "P-border-dashed",
            sortData: {
              isSort: true,
              sortKey: "resellers",
              sortValue: ""
            },
            cellTotalData: data =>
                    `<p class="G-total-sum">${this.totalResellers ? this.totalResellers : 0}</p>`
          },
          {
            title: "Total Logins",
            isTranslate: true,
            style: {minWidth: 120},
            isShow: true,
            isDrag: true,
            key: 'logins',
            defaultHide: true,
            defaultDragIndex: 7,
            mainIndex: 7,
            cellView: row => `<p class="G-text-table">${row.logins && row.logins.length ? row.logins : '-'}</p>`,
            totalData: true,
            totalText: "Total Sum",
            className: "P-border-dashed",
            sortData: {
              isSort: true,
              sortKey: "totalLogins",
              sortValue: ""
            },
            cellTotalData: data => `<p class="G-total-sum">${this.totalLogins ? this.totalLogins : 0}</p>`
          },
          {
            title: "Active Logins",
            isTranslate: true,
            style: {minWidth: 100},
            isShow: true,
            isDrag: true,
            key: 'activeLogins',
            defaultHide: true,
            defaultDragIndex: 8,
            mainIndex: 8,
            cellView: row => `<p class="G-text-table">${row?.activeLogins}</p>`,
            totalData: true,
            totalText: "Total Sum",
            className: "P-border-dashed",
            sortData: {
              isSort: true,
              sortKey: "activeLogins",
              sortValue: ""
            },
            cellTotalData: data =>
                    `<p class="G-total-sum">${this.totalActiveLogins ? this.totalActiveLogins : 0}</p>`
          },
          {
            title: "Inactive Logins",
            isTranslate: true,
            style: {minWidth: 100},
            isShow: true,
            isDrag: true,
            key: 'inactiveLogin',
            defaultHide: true,
            defaultDragIndex: 9,
            mainIndex: 9,
            cellView: row => `<p class="G-text-table">${row?.inactiveLogin}</p>`,
            totalData: true,
            totalText: "Total sum",
            className: "P-border-dashed",
            cellTotalData: data =>
                    `<p class="G-total-sum">${this.totalInactiveLogins ? this.totalInactiveLogins : 0}</p>`
          },
          {
            title: "Price Group",
            isTranslate: true,
            style: {minWidth: 150},
            isShow: true,
            isDrag: true,
            key: 'priceGroup',
            defaultHide: true,
            defaultDragIndex: 10,
            mainIndex: 10,
            sortData: {
              isSort: true,
              sortKey: "priceGroup",
              sortValue: ""
            },
            cellView: row => `<p class="G-text-table">${this.getPriceGroup(row.priceGroup)}</p>`
          },
          {
            title: "Current Month Payments",
            isTranslate: true,
            style: {minWidth: 220},
            isShow: true,
            isDrag: true,
            key: 'currentMonthPayments',
            defaultHide: true,
            defaultDragIndex: 11,
            mainIndex: 11,
            sortData: {
              isSort: true,
              sortKey: "currentMonthPayments",
              sortValue: ""
            },
            className: "P-border-dashed",
            cellView: row => `
              <p class="G-text-table">${
                row.transactions.length 
                  ? this.numberToFinanceNumber(row.currentMonthPayments)
                  : '-'
              }</p>`,
            totalData: true,
            totalText: "Total Sum",
            cellTotalData: data =>
              `<p class="G-total-sum">${
                this.totalCurrentMonthPayments !== 0 
                  ? Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    }).format(this.totalCurrentMonthPayments)
                  : '$0.00' 
                }
              </p>`
          },
          {
            title: "Current Month Income",
            isTranslate: true,
            style: {minWidth: 220},
            isShow: true,
            isDrag: true,
            key: 'currentMonthIncome',
            defaultHide: true,
            defaultDragIndex: 12,
            mainIndex: 12,
            sortData: {
              isSort: true,
              sortKey: "currentMonthIncome",
              sortValue: ""
            },
            cellView: row => `<p class="G-text-table">${
              row.transactions.length 
                ? this.numberToFinanceNumber(row.currentMonthIncome) 
                : '-'
              }
            </p>`,
            totalData: true,
            totalText: "Total Sum",
            className: "P-border-dashed",
            cellTotalData: data =>
             `<p class="G-total-sum">${
                this.totalCurrentMonthIncome !== 0 
                  ? Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    }).format(this.totalCurrentMonthIncome)
                  : '$0.00'
              }
             </p>`
          },
          {
            title: "Credit from Parent",
            isTranslate: true,
            style: {minWidth: 220},
            isShow: true,
            isDrag: true,
            key: 'creditFromParent',
            defaultHide: true,
            defaultDragIndex: 13,
            mainIndex: 13,
            cellView: row => `<p class="G-text-table">${this.getCreditAmount(row.credits)}</p>`,
            totalData: true,
            totalText: "Total Sum",
            className: "P-border-dashed",
            sortData: {
              isSort: true,
              sortKey: "creditFromParent",
              sortValue: ""
            },
            cellTotalData: data => 
              `<p class="G-total-sum">
                ${
                  this.totalCreditFromParent
                    ? 
                      `${
                        this.totalCreditFromParent !== 0 
                          ?   Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                              }).format(this.totalCreditFromParent)
                          : '$0.00'
                        }` 
                    : ''
                }
              </p>`
          },
          {
            title: "Credit grant date ",
            isTranslate: true,
            style: {minWidth: 220},
            isShow: true,
            isDrag: true,
            key: 'creditStartDate',
            defaultHide: true,
            defaultDragIndex: 14,
            mainIndex: 14,
            className: "P-border-dashed",
            sortData: {
              isSort: true,
              sortKey: "creditGrantDate",
              sortValue: ""
            },
            cellView: row => `<p class="G-text-table">${this.getCreditStartDate(row.credits)}</p>`
          },
          {
            title: "Credit Term",
            isTranslate: true,
            style: {minWidth: 200},
            isShow: true,
            isDrag: true,
            key: 'creditTerm',
            defaultHide: true,
            defaultDragIndex: 15,
            mainIndex: 15,
            cellView: row => `<p class="G-text-table">${this.getCreditTerm(row.credits)}</p>`
          },
          {
            title: "Credit Autoextend",
            isTranslate: true,
            style: {minWidth: 170},
            isShow: true,
            isDrag: true,
            key: 'creditAutoExtend',
            defaultHide: true,
            defaultDragIndex: 16,
            mainIndex: 16,
            className: "P-border-dashed",
            sortData: {
              isSort: true,
              sortKey: "creditAutoextend",
              sortValue: ""
            },
            cellView: row => `<p class="G-text-table">${this.getCreditAutoExtend(row.credits)}</p>`
          },
          {
            title: "Clients to pause after days ",
            isTranslate: true,
            style: {minWidth: 220},
            isShow: true,
            isDrag: true,
            key: 'clientsPauseAfterDays',
            defaultHide: true,
            defaultDragIndex: 17,
            mainIndex: 17,
            cellView: row => `<p class="G-text-table">${this.getClientsPauseAfterDays(row.credits)}</p>`
          },
          {
            title: "Days remain to credit end",
            isTranslate: true,
            style: {minWidth: 220},
            isShow: true,
            isDrag: true,
            key: 'daysRemainCreditEnd',
            defaultHide: true,
            defaultDragIndex: 18,
            mainIndex: 18,
            sortData: {
              isSort: true,
              sortKey: "daysRemainCreditEnd",
              sortValue: ""
            },
            cellView: row => `<p class="G-text-table">${this.getCreditTerm(row.credits)}</p>`
          },
          {
            title: "Days remain to pause client",
            isTranslate: true,
            style: {minWidth: 220},
            isShow: true,
            isDrag: true,
            key: 'daysRemainPauseClient',
            defaultHide: true,
            defaultDragIndex: 19,
            mainIndex: 19,
            cellView: row => `<p class="G-text-table">${row.daysRemainPauseClient || '-'}</p>`
          },
          {
            title: "Balance",
            isTranslate: true,
            style: {minWidth: 120},
            isShow: true,
            isDrag: true,
            className: "P-border-dashed",
            key: 'balance',
            defaultHide: true,
            defaultDragIndex: 20,
            mainIndex: 20,
            sortData: {
              isSort: true,
              sortKey: "balance",
              sortValue: ""
            },
            cellView: row => `<p class="G-text-table">
                                ${ this.numberToFinanceNumber(row.balance) }
                              </p>`
          },
          {
            title: "Debt",
            isTranslate: true,
            style: {minWidth: 120},
            isShow: true,
            isDrag: true,
            key: 'debt',
            defaultHide: true,
            mainIndex: 21,
            cellView: row => `<p class="G-text-table">
              ${ 
                row.debt !== 0 
                  ? this.numberToFinanceNumber(row.debt)
                  : '$0.00'
              }
            </p>`,
            totalData: true,
            totalText: "Total sum",
            className: "P-border-dashed",
            sortData: {
              isSort: true,
              sortKey: "debt",
              sortValue: ""
            },
            cellTotalData: data => `<p class="G-total-sum">
              ${
                this.totalDebt !== 0 
                  ?  Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    }).format(this.totalDebt)
                  : '$0.00'
              }
            </p>`
          },
          {
            title: "Own Channels",
            isTranslate: true,
            style: {minWidth: 170},
            isShow: true,
            isDrag: true,
            key: 'channels',
            defaultHide: true,
            defaultDragIndex: 22,
            mainIndex: 22,
            cellView: row => `<p class="G-text-table">${row.channels || 0}</p>`,
            totalData: true,
            totalText: "Total Sum",
            className: "P-border-dashed",
            cellTotalData: data =>
                    `<p class="G-total-sum">${this.totalOwnChannels ? this.totalOwnChannels : 0}</p>`
          },
          {
            title: "Client Packages",
            isTranslate: true,
            style: {minWidth: 170},
            isShow: true,
            isDrag: true,
            key: 'clientPackages',
            defaultHide: true,
            defaultDragIndex: 23,
            mainIndex: 23,
            cellView: row => `<p class="G-text-table">${row.clientPackages || 0}</p>`
          },
          {
            title: "Channels in Client Packages",
            isTranslate: true,
            style: {minWidth: 200},
            isShow: true,
            isDrag: true,
            key: 'channelsInCLientPackages',
            defaultHide: true,
            defaultDragIndex: 24,
            mainIndex: 24,
            cellView: row =>
                    `<p class="G-text-table">${row.channelsInCLientPackages || 0}</p>`
          },
          {
            title: "Reselling Packages",
            isTranslate: true,
            style: {minWidth: 170},
            isShow: true,
            isDrag: true,
            key: 'resellingPackages',
            defaultHide: true,
            defaultDragIndex: 25,
            mainIndex: 25,
            cellView: row => `<p class="G-text-table">${row.resellingPackages || 0}</p>`
          },
          {
            title: "Channels in Reselling Packages",
            isTranslate: true,
            style: {minWidth: 250},
            isShow: true,
            isDrag: true,
            key: 'channelsResellingPackages',
            defaultHide: true,
            defaultDragIndex: 26,
            mainIndex: 26,
            cellView: row =>
                    `<p class="G-text-table">${row.channelsResellingPackages || 0}</p>`
          },
          {
            title: "Statuses",
            isTranslate: true,
            style: {minWidth: 170},
            isShow: true,
            isDrag: true,
            key: 'state',
            defaultHide: true,
            defaultDragIndex: 27,
            mainIndex: 27,
            cellView: row => `<p class="G-text-table">${this.getProviderStatus(row.state)}</p>`
          },
          {
            title: "Date Registered",
            isTranslate: true,
            style: {minWidth: 180},
            isShow: true,
            isDrag: true,
            className: "P-border-dashed",
            key: 'createdAt',
            defaultHide: true,
            defaultDragIndex: 28,
            mainIndex: 28,
            sortData: {
              isSort: true,
              sortKey: "createdAt",
              sortValue: ""
            },
            cellView: row => `<p class="G-text-table">${ row.createdAt }</p>`
          },
          {
            title: "Registered By",
            isTranslate: true,
            style: {minWidth: 180},
            isShow: true,
            isDrag: true,
            key: 'registerBy',
            defaultHide: true,
            defaultDragIndex: 29,
            mainIndex: 29,
            cellView: row => `<p class="G-text-table">${this.getRegisteredByName(row.registerBy)}</p>`
          }
        ]
      },

      rowsPerPage: {
        set(limit) {
            this.setLimit(limit)
        },
        get() {
            return this.filter.limit;
        }
      },
      
      multipleActions() {
        let actionsList = []

        if (this.permissionIs('updateOttProvider') && this.permissionIs('updateClient')) {
          actionsList = [
            { title: "Move to", value: 'moveTo' },
            { title: "Block access", value: 'blockAccess' },
            { title: "Enable access", value: 'enableAccess' },
            { title: "Block all", value: 'blockAll' },
            { title: "Move client", value: 'moveClient' },
            { title: "Pause client", value: 'pauseClient' },
            { title: "Block client", value: 'blockClient' },
          ]
        }

        if (this.permissionIs('updateOttProvider') && !this.permissionIs('updateClient')) {
          actionsList = [
            { title: "Move to", value: 'moveTo' },
            { title: "Block access", value: 'blockAccess' },
            { title: "Enable access", value: 'enableAccess' },
            { title: "Block all", value: 'blockAll' },
          ]
        }

        if (!this.permissionIs('updateOttProvider') && this.permissionIs('updateClient')) {
          actionsList = [
            { title: "Move client", value: 'moveClient' },
            { title: "Pause client", value: 'pauseClient' },
            { title: "Block client", value: 'blockClient' },
          ]
        } 

        return actionsList         
      },

      warningModalHeading() {
        return `${this.warningModalBtnText} Provider(s) ?`
      },

      warningModalText() {
        return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> one or more Providers! <br />  If you're not sure, you can cancel this operation.`
      },
    },
    
    async created() {
      this.loading = true
      await this.getProviderSettings()
      this.createColumnConfigData()
      this.sortColumn();
      await this.getProviders(1).finally(() => {
        this.loading = false
      })
    },

    beforeDestroy() {
      this.filterModel.reset()
      this.addProviderModalSetData({ 
        filter: {
          limit: 20,
          sortBy: "_id:desc",
          page: 1,
        } 
      })
    },

    destroyed() {
      this.setFilterPage(1)
    },

    methods: {
      ...mapActions({
        deleteProvider: 'addProviderModal/deleteProvider',
        getPaymentDataList: 'paymentMethods/getPaymentDataList',
        doMultipleAction: 'providers/doMultipleAction',
        syncLiveAction: 'providers/syncLiveAction',
        multipleDelete: 'providers/multipleDelete',
        getFiltersData: 'addProviderModal/getFiltersData',
        getProvidersList: 'addProviderModal/getProvidersList',
        getProviderSettings: 'addProviderModal/getProviderSettings',
      }),
      ...mapMutations({
        setData: 'providers/setData',
        addProviderModalSetData: 'addProviderModal/setData',
        setLimit: 'addProviderModal/setLimit',
        setActiveIndex: 'sidebar/setActiveIndex',
        setProviderId: 'addProviderModal/setProviderId',
        setSelectedProvider: 'addProviderModal/setSelectedProvider',
        setCurrentProviderId: 'addProviderModal/setCurrentProviderId',
        setFilterPage: 'addProviderModal/setFilterPage',
        sortColumn: 'addProviderModal/sortColumn',
        resetFilterModel: 'addProviderModal/resetFilterModel',
        openProvidersModal: 'addProviderModal/openModal',
        setIsAddReseller: 'addProviderModal/setIsAddReseller',
        setActiveTab: 'addProviderModal/setActiveTab',
        setClientData: 'clientsModule/setData',
      }),

      /**
       * Reject provider form table  current  provider
       * or  selected some  providers
       * **/

      async updateLimit() {
        this.sortColumn();
        await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
          this.createSaveDefaultData();
        })
        
        await this.getProviders()
      },

      async rejectProviders() {
        if (this.deletedProviderId) {
          await this.deleteProvider({providerId: this.deletedProviderId}).then(async () => {
            await this.getProviders()
          })
        } else {
          this.deleteProviders()
        }
      },
      async selectAction() {
        const [event, data, menu] = arguments;
        let provider = this.getDataFromActions(data);
        if (menu.value === 'delete') {

          this.warningModalBtnText = "Delete"
          this.warningModalType = "delete"
          this.warningModalIsDangerButton = true
          this.setData({ showWarningModal : true })
          this.deletedProviderId = provider.id;
          // this.$store.dispatch('addProviderModal/deleteProvider', provider.id).then(() => {
          //   this.getProviders()
          //   this.resetSelectedList()
          // })
        }
        if (menu.value === 'edit') {
          this.setActiveTab(-1)
          this.setProviderId(provider.id)
          this.setSelectedProvider(provider)
          this.setData({ isEditProvider: true })

          await Promise.all([this.getPaymentDataList(provider.id)])

          this.openProvidersModal()
        }

        if (menu.value === 'addReseller') {
          this.setData({isEditProvider: false})
          this.setIsAddReseller(true)
          this.setCurrentProviderId(provider.id)
          this.setSelectedProvider(provider)
          this.openProvidersModal()
        }

        // if (menu.value === 'goToUsersTable') {
        //   this.setActiveIndex(2)
        //   this.$router.push({name:'UsersList'})
        // }
        if (menu.value === 'goToClientsTable') {
          this.setActiveIndex(3)
          this.setClientData({ 
            isTableLoading: true, 
            clientType: 'resellerClients', 
            resellerClients: true, 
            providerId: provider.id 
          })
          this.$router.push({ name:'ClientsList' })
        }
      },

      selectMultipleAction(action) {
        if (!this.isSelectedAll && this.selectedArrowList.length) {
          this.multipleActionsData = {
            actionStatus: action,
            ottproviderId : [...this.selectedArrowList.map(item => item.id)]
          }
        }

        if (this.isSelectedAll) {
          this.multipleActionsData = {
            actionStatus: action,
            all: true
          }
        }
        
        switch (action) {
          case "delete":
            this.warningModalBtnText = "Delete"
            this.warningModalType = "multipleDelete"
            this.warningModalIsDangerButton = true
            break; 

          case "blockAccess":
            this.warningModalBtnText = "Block Access"
            this.warningModalType = "multipleBlockAccess"
            this.warningModalIsDangerButton = true
            break;

          case "enableAccess":
            this.warningModalBtnText = "Enable Access"
            this.warningModalType = "multipleEnableAccess"
            this.warningModalIsDangerButton = false
            break; 

          case "blockAll":
            this.warningModalBtnText = "Block All"
            this.warningModalType = "multipleBlockAll"
            this.warningModalIsDangerButton = true
            break; 

          case "pauseClient":
            this.warningModalBtnText = "Pause Client"
            this.warningModalType = "multiplePauseClient"
            this.warningModalIsDangerButton = true
            break;

          case "blockClient":
            this.warningModalBtnText = "Block Client"
            this.warningModalType = "multipleBlockClient"
            this.warningModalIsDangerButton = true
            break; 
        }

        this.setData({showWarningModal: true})

      }, 

      async exportData() {
        await this.getProvidersList({ excel: true, ...this.addProviderModal.filter });
        this.resetSelectedList()
      },

      async getProviders(page) {
        if (page) {
          this.setFilterPage(page)
        }
        await this.getProvidersList(this.addProviderModal.filter)
        this.resetSelectedList()
      },
      openModalEdit() {
      },
      deleteClient() {
      },
      async sortColumnData(data) {
        this.sortColumn(data)
        await this.getProvidersList(this.addProviderModal.filter)
      },
      async resetData() {
        this.filterModel.reset();
        this.resetFilterModel()
        await this.getProvidersList(this.filter)
        this.resetSelectedList()
      },
      openModal() {
        this.setData({ isEditProvider: false })

        this.openProvidersModal()
      },

      /**
       * Delete providers List
       * **/

      async deleteProviders() {
        let data = {}
        if (!this.isSelectedAll && this.selectedArrowList.length) {
          data.ottproviderId = [...this.selectedArrowList.map(item => item.id)]
        }
        
        if (this.isSelectedAll) {
          data.all = true
        }
          
        await this.multipleDelete(data).then(async () => {
          await this.resetSelectedList()
        })
      }, 
      async resetDataTableSettings() {
        await this.resetDataTable() 
        await this.getProviderSettings()
        this.createColumnConfigData()
      },

      /**
       * Warning modal functions
       * **/
      async warningModalBtnFunc() {
        await this.warningModalBtnFunction().finally(() => {
          this.closeWarningModal();
        })
      },

      async warningModalBtnFunction() {
        this.warningModalIsLoading = true
        switch (this.warningModalType) {
          case 'delete':
          case 'multipleDelete':
            await this.rejectProviders()
            break; 
          case 'multipleBlockAccess':
          case 'multipleEnableAccess':
          case 'multipleBlockAll':
          case 'multiplePauseClient':
          case 'multipleBlockClient':
            await this.doMultipleAction(this.multipleActionsData)
            break;
          case 'syncProviders':
            await this.syncLiveFunction();
            break;
        }
      },
      async syncLiveFunction() {
        const res = await this.syncLiveAction();
        if (res.status) {
          console.log(res.data);
        }
        // TODO - refresh data
        // this.setData({ isOpenRefreshModal: true })
      },
      closeWarningModal() {
        this.warningModalIsLoading = false;
        this.setData({ showWarningModal : false })
      },

      getAddress(addresses) {
        if (addresses) {
          if (addresses.length) {
            let isMainAddress = addresses.filter(address => address.isMain)[0]
            if (isMainAddress) {
              return this.FormatProviderAddress(isMainAddress);
            } else {
              return '-'
            }
          } else {
            return '-'
          }
        } else {
          return '-'
        }
      },
      getPriceGroup(object) {
        if (object) {
          return object.name[0].name
        } else {
          return 'Default'
        }
      },
      getCreditAmount(credit) {
        if (credit.length) {
          let lastCredit =  credit[credit.length - 1]
          let creditAmount = lastCredit.creditAmount
          if (creditAmount !== undefined && typeof creditAmount === 'number') {
            return  this.numberToFinanceNumber(creditAmount)
          } else {
            return '-'
          }
        } else {
          return '-'
        }
      },
      getCreditStartDate(credit) {
        if (credit.length) {
          let lastCredit =  credit[credit.length - 1];
          let creditStart = lastCredit.creditStartDate;
          const momentObject = this.$moment(creditStart, this.ottDateFormat);

          if (isNaN(new Date(creditStart))) {
            return this.$moment(momentObject.toDate()).format(`${this.ottDateFormat} HH:mm`)
          } else {
            return this.$moment(creditStart).format(`${this.ottDateFormat} HH:mm`)
          }

        } else {
          return '-'
        }
      },
      getCreditTerm(credit) {
        if (credit.length) {
          let lastCredit =  credit[credit.length - 1];
          let creditTerm = lastCredit.creditTerm
          if (creditTerm <= 5) {
            return `<span style="color: #F53A3A">${creditTerm} days</span>`
          } else {
            return creditTerm + ' days'
          }
        } else {
          return '-'
        }
      },
      getCreditAutoExtend(credit) {
        if (credit.length) {
          let lastCredit =  credit[credit.length - 1];
          let creditAutoextend = lastCredit.creditAutoextend
          if (creditAutoextend) {
            return '<span class="mdi mdi-check" style="font-size: 20px; color: #5FC788; margin-left: 30px" />'
          } else {
            return ''
          }
        } else {
          return ''
        }
      },
      getClientsPauseAfterDays(credit) {
        if (credit.length) {
          let lastCredit =  credit[credit.length - 1];
          let clientsPauseAfterDays = lastCredit.clientsPauseAfterDays
          if (clientsPauseAfterDays) {
            return clientsPauseAfterDays + ' days'
          } else {
            return ''
          }
        } else {
          return ''
        }
      },
      getRegisteredByName(data) {
        if (data && data.length) {
          let fullName = data.filter(name => name.lang === 'en')
          if (fullName[0]) {
            return fullName[0].firstname + ' ' + fullName[0].lastname
          }
        } else {
          return '-'
        }
      },
      getProviderStatus(status) {
        if (status === 0) {
          return '<span class="G-badge G-pending-badge G-flex G-align-center">Pending</span>'
        } else if (status === 1) {
          return '<span class="G-badge G-approve-badge G-flex G-align-center">Approved</span>'
        } else if (status === 2) {
          return '<span class="G-badge G-reject-badge G-flex G-align-center">Reject</span>'
        } else {
          return '<span class="G-badge G-disabled-badge G-flex G-align-center">Disabled</span>'
        }
      },
      getCountryName(countryCode) {
        let name = ''
        allCountry.forEach(item => {
          if (item.value === countryCode) {
            name = item.name
          }
        })
        return name
      },
      getMainEMail(emails) {
        if (emails && emails.length) {
          let x = emails.filter(x => x.isMain)[0]
          if (x) {
            return x.address
          } else {
            return '-'
          }
        } else {
          return '-'
        }
      },

      refreshPage() {
        this.warningModalType = 'syncProviders'
        this.warningModalBtnText = 'Sync'
        this.warningModalIsDangerButton = false
        this.setData({ showWarningModal : true })
      }
    },
  };
</script>
<style lang="scss" scoped></style>

<style lang="scss">
  @import "src/assets/scss/variables";

  .P-filter-open {
    .mdi-menu-right {
      transform : rotate(180deg);
    }
  }

  .P-providers-list {
    position : relative;
  }

  .approve-rej-icon-cont {
    font-size : 24px;

    .mdi {
      padding : 0 7px;
      display : inline-block;
    }

    .mdi-cancel,
    .mdi-check-circle-outline {
      border-radius : $border-radius;
    }
  }

  .approve-rej-icon-cont::v-deep {
    .v-tooltip__content {
      border-radius : 6px;
      padding       : 7px 9px;
      font-size     : $txt12;
    }
  }

  .select-all-menu-cont {
    .P-icon {
      font-size : 26px !important;
    }
  }

  .select-all-menu-cont button .mdi-dots-vertical,
  .settings-btn button .mdi-settings-outline {
    height        : 38px;
    font-size     : 24px;
    width         : 38px;
    border-radius : 4px;
  }

  .theme--light {
    .active-filter-count {
      background-color : #e5f5f6 !important;
      color            : $primary-color !important;
    }

    .action-cont-first-cont::v-deep {
      .v-icon {
        color : $neutral-color;
      }

      .mdi-minus-box {
        color : $primary-color !important;
      }
    }

    .select-all-menu-cont button[aria-expanded="true"] .mdi-dots-vertical,
    .settings-btn button[aria-expanded="true"] .mdi-settings-outline {
      background : $neutral-color15;
      color      : $secondary-color !important;
    }

    .select-all-menu-cont button .mdi-dots-vertical:hover,
    .settings-btn button .mdi-settings-outline:hover {
      background : $neutral-color15;
      color      : $secondary-color !important;
    }

    .icon-active {
      background    : $neutral-color;
      color         : $secondary-color !important;
      border-radius : $border-radius;
    }
  }

  .theme--dark {
    .action-cont-first-cont::v-deep {
      .v-icon {
        color : $neutral-colorDM !important;

        &.v-icon:after {
          opacity : 0;
        }
      }

      .mdi-minus-box {
        color : $primary-colorDM !important;
      }

      .primary--text {
        color : $primary-colorDM !important;
      }
    }

    .action-cont-first {
      border-right : 1.5px solid $borderDM;
    }
  }

  .G-excel-btn {
    border-left: unset;
    padding-left: unset;
  }

  .action-cont-first {
    display       : flex;
    border-right  : 1.5px solid $neutral-color;
    height        : 36px;
    align-items   : center;

    .v-input--selection-controls {
      margin-top : 23px;
    }
  }


  .P-icon {
    position   : relative;
    z-index    : 5;
    transition : $transition;
  }

  .P-providers-columns {
    padding-left: 10px;
    .P-column-configs {
      max-width : 820px !important;
    }

    .P-column-configs ul li {
      width : 33.3%;
    }

    .v-menu__content {
      max-height : 520px !important;
    }
  }

  .P-actions-list {
    p {
      margin-bottom: 0;
      a {
        color: inherit;
      }
    }
  }

  .P-refresh-btn {
    margin-left: 16px;
  }
</style>

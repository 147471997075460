<template>
  <ott-dialog
    :is-open="addProviderModal.modal.rejectModal"
    :width="400"
    :show-footer="false"
    :show-header="false"
    @outside="$store.commit('closeModal', 'isRejectModal')"
  >
    <template v-slot:modalBody>
      <div class="reject-cont ">
        <div class="header">
          <span class="mdi mdi-cancel red--text"></span>
          <h6 class="secondary--text">Delete provider(s) ?</h6>
        </div>
        <div>
          <p class="secondary--text">
            You're about to Delete one or more providers!
          </p>
          <p class="secondary--text">
            If you're not sure, you can cancel this operation.
          </p>
        </div>
      </div>
      <div class="footer">
        <OttButtonLoader
          :is-loading="isLoading"
          @onClick="rejectButton"
          button-text="Delete"
          className="red-sm-btn"
        />
        <!--      <ott-button :click="rejectButton" text="Reject" class="red-sm-btn" />-->
        <span @click="closeRejectModal" class="cancel secondary--text"
        >Cancel</span
        >
      </div>
    </template>
  </ott-dialog>
</template>

<script>
  import { mapState, mapMutations } from "vuex";
  import OttButton from "@/components/vuetifyComponents/OttButton";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
  import OttDialog from "@/components/vuetifyComponents/OttDialog";

  export default {
    name: "RejectModalContent",
    components: {OttDialog, OttButtonLoader, OttButton},
    computed: {
      ...mapState({
        addProviderModal: state => state.addProviderModal,
        isLoading: state => state.appModule.isLoading
      })
    },

    methods: {
      ...mapMutations({
        toggleOpenRejectModal : 'addProviderModal/toggleOpenRejectModal'
      }),
      async rejectButton() {
        this.$emit('rejectProvider')
      },

      closeRejectModal() {
        this.toggleOpenRejectModal(false);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";
  @import "./src/assets/scss/mixins";

  .header {
    display       : flex;
    align-items   : center;
    margin-bottom : 20px;

    h6 {
      font-size   : $txt18;
      font-weight : $semiBold;
      margin-left : 12px;
    }
  }

  .reject-cont {
    padding : 24px;

    .mdi-cancel {
      font-size : 20px;
    }

    p {
      margin-bottom : 20px;
      font-size     : $txt14;
      font-weight   : $regular;
    }
  }

  .footer {
    display         : flex;
    padding         : 24px;
    justify-content : flex-end;

    .red-btn {
      margin-right : 14px;
    }

    .cancel {
      font-size   : $txt15;
      font-weight : $semiBold;
      padding     : 0 16px;
      display     : flex;
      align-items : center;
      margin-left : 15px;
      cursor      : pointer;

      &:hover {
        background    : #f0f1f5;
        border-radius : $border-radius;
      }
    }
  }

  .theme--dark {
    .cancel:hover {
      background    : #54575a !important;
      border-radius : $border-radius;
    }
  }
</style>

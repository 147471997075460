import { render, staticRenderFns } from "./RejectModalContent.vue?vue&type=template&id=4272213c&scoped=true"
import script from "./RejectModalContent.vue?vue&type=script&lang=js"
export * from "./RejectModalContent.vue?vue&type=script&lang=js"
import style0 from "./RejectModalContent.vue?vue&type=style&index=0&id=4272213c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4272213c",
  null
  
)

export default component.exports